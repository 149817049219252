
<script>
const bookingNote = importVueComp('components/booking', 'BookingNote', 'custom');   
export default {
    name: 'custom-booking-note',
    mixins: [bookingNote],  
    data () {
        return {
            breakNote: true,
        }
    }
}
</script>



